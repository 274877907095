import { ApiService } from './ApiService'

class SearchServiceClass {
  constructor (
    private apiService: typeof ApiService
  ) { }

  async search (searchString: string, categoryList?: number[]) {
    const category_ids = categoryList ? categoryList : undefined;

    return this.apiService.post('/search/universal', {
      search_text: searchString,
      category_ids: category_ids
    })
  }

  async loadMore (searchString: string, page: number, category: string, items_per_page: number, category_list?: number[]) {
    const categString = category.toLocaleLowerCase()
    const category_ids = (category_list && category_list.length) ? category_list : undefined
    const res = await this.apiService.post(`/search/${categString}/${page}/${items_per_page}`, {
      search_text: searchString,
      category_ids: category_ids
    })
    return res
  }
}

export const SearchService = new SearchServiceClass(ApiService)
