import { ICartStore } from '../cart/types'
import { CatalogItemImage } from '../catalog/types'

export interface ISearchBase {
  more_available: boolean,
  currentPage: number,
  totalPages: number,
  totalItems: number,
  itemsPerPage: number
}

export interface ISearchCategory {
  id: number
  title: string
  image?: CatalogItemImage
  department_id: number
  default?: boolean
}

export interface ISearchCategories extends ISearchBase {
  list: ISearchCategory[]
}

export interface ISearchDepartment {
  id: number
  title: string
  image?: CatalogItemImage
  categories: ISearchCategories[]
}

export interface ISearchDepartments extends ISearchBase {
  list: ISearchDepartment[]
}

export interface ISearchStores extends ISearchBase {
  list: ICartStore[]
}

export interface ISearchProduct {
  id: number
  name: string
  model: string | null;
  description: string | null;
  images: CatalogItemImage[]
  real_sale_price: number
  sale_price: number
}

export interface ISearchProducts extends ISearchBase {
  list: ISearchProduct[]
}

export interface ISearchResultCategory {
  products: ISearchProducts,
  categories: ISearchCategories,
  departments: ISearchDepartments,
  stores: ISearchStores
  initialized: boolean
}

export const SEARCH_LOADING_STATE = 'SEARCH_LOADING_STATE'
interface SearchLoadingState {
  type: typeof SEARCH_LOADING_STATE
  payload: boolean
}

export const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA'
interface SearchClearData {
  type: typeof CLEAR_SEARCH_DATA
}

export const SEARCH_FETCHED = 'SEARCH_FETCHED'
interface SearchFetched {
  type: typeof SEARCH_FETCHED
  payload: ISearchResultCategory[]
}

export const SEARCH_MORE_ITEMS = 'SEARCH_MORE_ITEMS'
interface SearchMoreItems {
  type: typeof SEARCH_MORE_ITEMS
  payload: ISearchProducts | ISearchCategories | ISearchDepartments
  category: string
}

export const SEARCH_PAGE = 'SEARCH_PAGE'
interface SearchPage {
  type: typeof SEARCH_PAGE
  payload: ISearchProducts | ISearchCategories | ISearchDepartments
  category: string
}

export const PRE_SEARCH_LOADING_STATE = 'PRE_SEARCH_LOADING_STATE'
interface PreSearchLoadingState {
  type: typeof PRE_SEARCH_LOADING_STATE
  payload: boolean
}

export const PRE_SEARCH_FETCHED = 'PRE_SEARCH_FETCHED'
interface PreSearchFetched {
  type: typeof PRE_SEARCH_FETCHED
  payload: ISearchResultCategory[]
}

export const PRE_SEARCH_VISIBLE = 'PRE_SEARCH_VISIBLE'
interface PreSearchVisible {
  type: typeof PRE_SEARCH_VISIBLE
  payload: boolean
}

export const PRE_SEARCH_CLEAR = 'PRE_SEARCH_CLEAR'
interface PreSearchClear {
  type: typeof PRE_SEARCH_CLEAR
}

export type SearchActions = SearchFetched | SearchClearData | SearchLoadingState | SearchMoreItems | SearchPage | PreSearchLoadingState | PreSearchFetched | PreSearchVisible | PreSearchClear

export interface ISearchResultItem {
  title: string
  description: string
  image: CatalogItemImage
}

export interface ISearchResult {
  categories?: ISearchCategories
  departments?: ISearchDepartments
  products?: ISearchProducts
  stores?: ISearchStores
  // news?: ISearchProducts
  loading?: boolean
}

export interface IPreSearchItem {
  image: string | null
  name: string
  title?: string
  type: string
  price?: string
  link: string
}

export interface IPreSearchState {
  preSearchResult: IPreSearchItem[],
  preSearchLoading: boolean,
  preSearchVisible: boolean
}
